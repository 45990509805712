<template>
  <section id="user-profile">
    <v-row class="d-flex justify-center" id="user-profile__wrapper">
      <v-col cols="10" class="pt-6">
        <h1 class="text-h5 font--secondary font-weight-large pb-8">
          {{ $dictionary.app.userProfile.title }}
        </h1>
        <user-profile-form
          @toogle-drawer="toggleDrawer"
          :is-drawer-open="isDrawerOpen"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <right-side-bar
          fixed
          divider
          prepend
          width="30%"
          header-style="mt-2 mr-2"
          :title="resetPassword.title"
          @toggle-right-bar="toggleDrawer"
          :is-right-drawer-open="isDrawerOpen"
        >
          <change-password-form @toogle-drawer="toggleDrawer" />
        </right-side-bar>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { RightSideBar } from "@/components/drawers";
import { UserProfileForm, ChangePasswordForm } from "@/components/forms";

/**
 * User profile view
 */
export default {
  name: "UserProfile",
  /**
   * -----------Components -------------
   */
  components: {
    RightSideBar,
    UserProfileForm,
    ChangePasswordForm,
  },
  /**
   * -------------- Data porperties --------------
   */
  data() {
    const { resetPassword } = this.$dictionary.app.userProfile;
    return {
      resetPassword,
      isDrawerOpen: false,
    };
  },
  /**
   * -------------- Methods --------------
   */
  methods: {
    /**
     * Toogels reset password right side drawer
     */
    toggleDrawer(val) {
      this.isDrawerOpen = val;
    },
  },
};
</script>
